@import '../../styles/propertySets.css';
.root {
  width: 100%;
  & > thead tr {
    border-bottom: 1px solid var(--matterColorAnti);
  }
  & > tbody td {
    border-bottom: 1px solid var(--matterColorAnti);
  }
}

.itemHeaders {
  & > th:first-child {
    padding: 20px 0;
    text-align: left;
  }
  & > th:nth-child(2) {
    display: none;
  }
  & > th:nth-child(4) {
    display: none;
  }
  & > th:nth-child(7) {
    display: none;
  }
  @media (--viewportMedium) {
    & > th:nth-child(2) {
      display: table-cell;
    }
    & > th:nth-child(4) {
      display: table-cell;
    }
    & > th:nth-child(7) {
      display: table-cell;
    }
  }
}

.rootForImage {
  display: none;
  @media (--viewportMedium) {
    display: block;
    /* Layout - image will take space defined by aspect ratio wrapper */
    width: 87px;
    height: 58px;
  }
}

/* Listing items */
.rootItem {
  & > td:not(:first-child) {
    padding: 10px 20px;
    text-align: center;
  }

  & > td:first-child {
    padding: 10px 40px 10px 0;
  }

  & > td:nth-child(2) {
    display: none;
  }
  & > td:nth-child(4) {
    display: none;
  }
  & > td:nth-child(7) {
    display: none;
  }

  @media (--viewportMedium) {
    & > td:nth-child(2) {
      display: table-cell;
    }
    & > td:nth-child(4) {
      display: table-cell;
    }
    & > td:nth-child(7) {
      display: table-cell;
    }
  }
}

.itemInfo {
  display: flex;
  align-items: center;
}

.itemTitle{
  margin: 0;
  
  @media (--viewportMedium) {
    margin: 0 0 0 20px;
  }
}

.itemView {
  & > a span {
    color: var(--marketplaceSecondaryColor);
  }
}

.itemRemove {
  padding: 20px 60px;
  & > img {
    cursor: pointer;
  }
}