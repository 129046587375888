@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.card {
  @apply --marketplaceInputStyles;

  /* Layout */
  padding: 8px 0 6px 0;
  height: 36px;
  width: auto;
  /* Border */
  border-bottom-color: var(--attentionColor);

  @media (--viewportMedium) {
    height: 38px;
  }
  @media (--viewportLarge) {
    height: 38px;
    padding: 6px 0 14px 0;
  }
}

.cardSuccess {
  border-bottom-color: var(--successColor);
}

.cardError {
  border-bottom-color: var(--failColor);
}

.error {
  color: var(--failColor);
}

.errorMessage {
  margin-top: 24px;
  color: var(--failColor);
}

.paymentHeading {
  font-family: var(--fontTitle);
  font-weight: normal;
  font-size: 14px;
  margin: 0 0 12px 0;
  color: var(--matterColorDark);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 0 0 16px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.saveForLaterUse {
  padding-top: 6px;
  margin-bottom: 25px;

  @media (--viewportMedium) {
    padding-top: 8px;
    margin-top: 0px;
  }
}

.saveForLaterUseCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
  }
}

.saveForLaterUseLabel {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}

.saveForLaterUseLegalInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  display: inline-block;
  padding: 0px 0 0px 20px;
  margin: -2px 0 2px 0;

  @media (--viewportMedium) {
    margin: -1px 0 1px 0;
  }
}

.billingHeading {
  font-family: var(--fontTitle);
  font-weight: normal;
  font-size: 14px;
  margin: 0;
  color: var(--matterColorDark);

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.paymentLabel {
  margin-top: 20px;
}

.messageHeading {
  font-family: var(--fontTitle);
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
  color: var(--matterColorDark);
  margin: 36px 0 12px 0;

  padding-top: 4px;
  padding-bottom: 2px;

  @media (--viewportMedium) {
    margin: 24px 0 18px 0;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.messageLabel {
  margin: 0 0 5px 0;

  @media (--viewportMedium) {
    margin: 0 0 6px 0;
  }
}

.messageOptional {
  color: var(--matterColorAnti);
}

.message {
  border-bottom-color: var(--matterColorAnti);
  margin-top: 10px;
  & textarea {
    padding-bottom: 0;
  }
}

.submitContainer {
  margin-top: auto;

  @media (--viewportLarge) {
    margin-top: 72px;
  }
}

.paymentInfo {
  @apply --marketplaceH5FontStyles;
  font-family: var(--fontTitle);
  color: var(--matterColorAnti);
  text-align: center;
  padding: 0 42px;
  margin: 12px 0 0 0;

  @media (--viewportLarge) {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 12px;
    text-align: left;
    padding: 0;
  }
}

.paymentInfoNote {
  composes: paymentInfo;
  padding: 0;
  text-align: left;
  font-size: 10px;
}

.submitButton {
  margin-top: 22px;

  @media (--viewportMedium) {
    margin-top: 26px;
  }
  @media (--viewportLarge) {
    margin-top: 17px;
    height: 64px;
  }

  &:hover {
    background: var(--marketplaceSecondaryColor);
    color: var(--matterColorLight);
  }
}

.missingStripeKey {
  color: var(--failColor);
}

.shippingOptions {
  margin-bottom: 20px;
  & span {
    color: var(--matterColorDark);
  }
}

.tabList {
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
}

.tabListItem {
  flex-grow: 1;
  text-align: center;
  background: var(--matterColorLightGray);
  border: 1px solid var(--matterColorLighterGray);
  padding: 22px 4px;
  font-family: var(--fontTitle);
  font-size: 14px;
  line-height: 24px;
}

.activeTabListItem {
  composes: tabListItem;
  background: var(--matterColorLight);
}

.paymentFormContent {
  background: var(--matterColorLight);
  padding: 24px;
  border-bottom: 1px solid var(--matterColorLighterGray);
  border-right: 1px solid var(--matterColorLighterGray);
  border-left: 1px solid var(--matterColorLighterGray);
}

.sameAsShippingCheckbox {
  composes: saveForLaterUseCheckbox;
}

.sameAsShippingLabel {
  color: var(--matterColor);
  font-family: var(--fontFamily);
  font-size: 14px;
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}

.wcPaymentOptions {
  font-size: 14px;
}

.wcPaymentNotes {
  & > p {
    font-size: 14px;
    line-height: 18px;
  }
}