@import '../../styles/propertySets.css';

.root {
  @apply --marketplaceModalRootStyles;

  @media (--viewportMedium) {
    @apply --backgroundImage;
  }
}

.layoutWrapperMain {
  min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.submitEmailContent,
.genericErrorContent,
.emailSubmittedContent,
.emailNotVerifiedContent {
  @apply --marketplaceModalBaseStyles;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceModalHelperLink;
}

.helperText {
  @apply --marketplaceModalHelperText;
}
