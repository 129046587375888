@import "../../styles/propertySets.css";

.root {
  display: flex;
  flex-direction: column;
}

.paymentAddressField {
  padding-top: 38px;
}

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    flex-wrap: no-wrap;
  }
}

.field {
  width: 100%;
  margin-top: 24px;

  @media (--viewportSmall) {
    width: calc(50% - 12px);
  }
}

.recipientField {
  width: 100%;
  margin-top: 24px;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  border: 1px solid var(--marketplaceSecondaryColor);

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }

  &:hover {
    background: var(--marketplaceSecondaryColor);
    color: var(--matterColorLight);
  }
}
