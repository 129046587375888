.root {
  display: flex;
  flex-direction: column;

  /* Layout: size and positioning */
  width: 100%;
  height: auto;
  margin-top: 24px;
  gap: 20px;
}

.error {
  color: var(--failColor);
}

.errorPlaceholder {
  @media (--viewportMedium) {
    width: 100%;
    height: 32px;
  }
}

.inputCounterOffer {
  & input {
    line-height: 32px;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 96px;
  flex: 1;

  @media (--viewportMedium) {
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
  }
}
