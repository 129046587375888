.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }

  @media (--viewportLarge) {
    min-width: 900px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 300px;
}

.askingPrice {
  margin-bottom: 32px;
}
.submitButton {
  margin-top: 100px;
  margin-bottom: 24px;
  border: 1px solid var(--marketplaceSecondaryColor);

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
  }

  &:hover {
    background: var(--marketplaceSecondaryColor);
    color: var(--matterColorLight);
  }
}

.dateField {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.dateSelect {
  max-width: 300px;
  &:disabled {
    cursor: not-allowed;
  }
}

.timeField {
  margin-top: 20px;
}

.automatedField {
  margin-top: 20px;
}

.note {
  font-size: 10px;
  margin: unset;
}