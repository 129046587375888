@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorLight); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: var(--borderRadius);
}

.soldBadge {
  font-family: var(--fontTitle);
  font-size: 16px;
  line-height: 45px;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 15px;
  right: 15px;
  background: var(--marketplaceSecondaryColor);
  color: white;
  padding: 0 8px;
  border-radius: 50%;
}

.info {
  /* Layout */
  padding: 16px 0;
  flex-grow: 2;
  display: flex;
  flex-direction: column;
}

.priceRow {
  display: flex;
  justify-content: space-between;
}

.infoRow {
  composes: priceRow;
}

.timerRow {
  composes: priceRow;
  justify-content: right;
  color: var(--marketplaceSecondaryColor);
  flex-grow: 2;
}

.timerBadge {
  position: absolute;
  display: flex;
  bottom: 5px;
  width: 100%;
  text-align: center;
  background: var(--matterColorLightTransparent);
  color: var(--marketplaceSecondaryColor);
  justify-content: space-between;
  padding: 0 5px;
}

.timerBadgeText {
  margin: 0;
  font-size: 16px;
}

.timedAuctionRibbon {
  width: 130px;
  height: 30px;
  line-height: 30px;
  padding-left: 15px;
  position: absolute;
  left: -4px;
  top: 20px;
  background: var(--marketplaceSecondaryColor);
  color: var(--matterColorLight);
  @media (--viewportMedium) {
    width: 150px;
  }
}
.timedAuctionRibbon:before, .timedAuctionRibbon:after {
  content: "";
  position: absolute;
}
.timedAuctionRibbon:before {
  height: 0;
  width: 0;
  top: -4.5px;
  left: 0px;
  border-bottom: 5px solid var(--marketplaceColor);
  border-left: 5px solid var(--matterColorTransparent);
}
.timedAuctionRibbon:after {
  height: 0;
  width: 0;
  right: -14.5px;
  border-top: 15px solid var(--matterColorTransparent);
  border-bottom: 15px solid var(--matterColorTransparent);
  border-left: 15px solid var(--marketplaceSecondaryColor);
}

.price {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
  flex-grow: 1;
}

.priceValue {
  /* Font */
  text-align: right;
  font-weight: var(--fontWeightMedium);
  font-size: 20px;
  color: var(--matterColor);
  line-height: 24px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.pricingType {
  font-family: var(--fontTitle);
  font-weight: var(--fontWeightMedium);
  font-size: 12px;
  color: var(--matterColorDark);
  line-height: 24px;
}

.watcher {
  color: var(--marketplaceSecondaryColor);
  font-size: 12px;
  line-height: 24px;
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorDark);
  font-weight: var(--fontWeightMedium);
  font-family: var(--fontTitle);
  font-size: 16px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.authorInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.estPriceLabel {
  color: var(--matterColorAnti);
  font-size: 14px;
  font-family: var(--fontTitle);
}

.estPriceValue {
  color: var(--matterColorAnti);
  font-size: 14px;
}
