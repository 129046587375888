.root {
  width: 50px;
  height: 50px;
  background-size: cover;
  background-image: url("./twitter-circle-white.png");

  &:hover {
    background-image: url("./twitter-circle-black.png");
  }
}
