.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }

  @media (--viewportLarge) {
    min-width: 900px;
  }
}

.error {
  color: var(--failColor);
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 32px;
}

.askingPrice {
  margin-bottom: 32px;
}
.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  border: 1px solid var(--marketplaceSecondaryColor);

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }

  &:hover {
    background: var(--marketplaceSecondaryColor);
    color: var(--matterColorLight);
  }
}

.estPriceInput {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.estPriceInputField {
  width: 200px;
  margin-bottom: 12px;
}