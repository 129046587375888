@import '../../styles/propertySets.css';

.root {
  position: absolute;
  color: var(--marketplaceSecondaryColor);
  outline: 0;
  border: none;
  cursor: pointer;
  & svg {
    width: 15px;
    height: 15px;
  }
  & span {
    display: inline-block;
    margin-left: 5px;
  }
}