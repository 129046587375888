.root {
  display: flex;
  flex-direction: column;
}

.paymentAddressField {
  padding-top: 38px;
}

.formRow {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap;

  @media (--viewportSmall) {
    flex-wrap: no-wrap;
  }
}

.field {
  width: 100%;
  margin-top: 24px;

  @media (--viewportSmall) {
    width: calc(50% - 12px);
  }
}

.field:first {
  margin-top: 0;
}

.optionalLabel {
  color: var(--matterColorAnti);
  margin-left: 5px;
}

.saveForLaterUseCheckbox {
  display: inline-block;
  padding: 2px 0 3px 0;

  @media (--viewportMedium) {
    padding: 1px 0 0px 0;
    margin: 30px 0 40px 0;
  }
}

.saveForLaterUseLabel {
  color: var(--matterColor);
  font-family: var(--fontFamily);
  font-size: 14px;
  margin-top: 3px;
  margin-bottom: 0px;
  cursor: pointer;
}