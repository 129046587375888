.root {
    margin: 0;
    padding: 0;
    border: none;
  }
  
  .list {
    margin: 0;
  }
  
  .twoColumns {
    @media (--viewportMedium) {
      columns: 2;
    }
  }
  
  .item {
    padding: 2px 0;
  
    /* Fix broken multi-column layout in Chrome */
    page-break-inside: avoid;
  }
  