@import '../../styles/propertySets.css';

.layoutWrapperMain {
  min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.root {
  @apply --marketplaceModalRootStyles;

  @media (--viewportMedium) {
    @apply --backgroundImage;
  }
}

.content {
  @apply --marketplaceModalBaseStyles;
  border-bottom: none;
}

.error {
  @apply --marketplaceModalErrorStyles;
  margin-bottom: 0;
  text-align: center;
}

.confirmUnsubscription {
  text-align: center;
  margin: 20px auto;
}

.unsubscribeButton {
  margin-top: 30px;
  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceSecondaryColor);
    color: var(--matterColorLight);
    cursor: pointer;
  }
}

.listingImage {
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

