@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* Text positioning is done with paddings */
}

.heroContent {
  width: 100%;
  order: 1;

  @media (--viewportLarge) {
    width: 50%;
    order: 0;
  }
}

.heroMainTitle,
.titleHighlight {
  @apply --marketplaceHeroTitleFontStyles;
  font-family: 'PT Serif', sans-serif;
  color: var(--matterColorDark);
  font-weight: var(--fontWeightMedium);
  font-size: 35px;
  line-height: 49px;

  composes: animation;
  animation-delay: 0.5s;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    font-size: 55px;
    line-height: 65px;
  }
}

.titleHighlight {
  font-weight: var(--fontWeightBold);
  letter-spacing: 0.25px;
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;
  font-family: 'PT Serif', sans-serif;
  font-size: 16px;

  color: var(--matterColorDark);
  margin: 0 0 32px 0;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    margin: 0 0 47px 0;
  }
}

.heroButton {
  font-family: var(--fontNavLink);
  composes: animation;
  background-color: var(--matterColorLight);
  color: var(--matterColorDark);
  border: 2px solid var(--marketplaceSecondaryColor);
  border-radius: 4px;
  text-align: center;
  line-height: 35px;
  width: 100%;
  height: 41px;
  display: block;
  margin: auto;

  animation-delay: 0.8s;

  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceSecondaryColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    display: block;
    line-height: 31px;
    height: 37px;
    width: 200px;
  }
}

.categories {
  margin-top: 10px;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 10px 10px;
  @media (--viewportMedium) {
    display: none;
  }
}

.categoryButton {
  composes: heroButton;
}

.heroContentContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 24px;
  width: 100%;
  max-width: 1300px;
  text-align: center;

  @media (--viewportMedium) {
    margin: 0 auto;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.heroImage {
  height: 55vw;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.4s ease-in;

  @media (--viewportLarge) {
    width: 50%;
    height: 400px;
    background-position: right;
  }
}
