@import '../../styles/propertySets.css';

/* Dummy Topbar */

.topbar {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  align-items: center;
  justify-content: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: var(--boxShadow);

  border-bottom: 1px solid var(--matterColorNegative);

  @media (--viewportLarge) {
    height: var(--topbarHeightDesktop);
    justify-content: center;
  }
}

.home {
  display: flex;
  padding: calc((var(--topbarHeight) - var(--CheckoutPage_logoHeight)) / 2) 24px;

  @media (--viewportLarge) {
    padding: calc((var(--topbarHeightDesktop) - var(--CheckoutPage_logoHeightDesktop)) / 2) 36px;
  }
}

.logoMobile {
  display: block;

  & path {
    fill: var(--marketplaceColor);
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.logoDesktop {
  display: none;
  width: auto;
  height: 27px;

  @media (--viewportLarge) {
    display: block;
  }
}

/* /Dummy Topbar */

/**
 * Content: mobile flow vs desktop 2 columns
 * desktop has reduced version of bookListingContainer (incl. title and Stripe paymentForm).
 * priceBreakdown is inside separate column (detailsContainerDesktop)
 */
.contentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0 48px;
  }
}

.bookListingContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-grow: 0;
    flex-basis: 519px;
    margin-top: 121px;
    margin-right: 50px;
  }

  @media (--viewportLargeWithPaddings) {
    margin-right: 132px;
  }
}

.aspectWrapper {
  position: relative;
  background-color: var(--matterColorNegative); /* Loading BG color */
  max-height: 300px;
  @media (--viewportMedium) {
    max-height: 600px;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: relative;
  object-fit: fill;
  max-height: 300px;
  width: 100%;
  @media (--viewportMedium) {
    max-height: 600px;
  }
  @media (--viewportLarge) {
    border-radius: 2px 2px 0 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Layout */
  display: block;

  @media (--viewportLarge) {
    margin-left: 48px;
  }
}

.avatarMobile {
  @media (--viewportLarge) {
    display: none;
  }
}

.heading {
  margin-top: 29px;
  margin-bottom: 34px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-top: 27px;
    margin-bottom: 30px;
  }

  @media (--viewportLarge) {
    margin-top: 0px;
    margin-bottom: 40px;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  font-weight: normal;
  font-size: 24px;
  color: var(--matterColorDark);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 7px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 28px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);

  /* Reset margins from font styles */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 37px;

  @media (--viewportMedium) {
    margin-bottom: 38px;
  }
  @media (--viewportLarge) {
    /* we don't show price breakdown since it will be visible within 2nd column (detailsContainerDesktop) */
    display: none;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 14px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 18px;
  }
  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 14px;
  }
}

.loadingScreen {
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--marketplaceColorLight);
  z-index: 2;
}

.loader {
  position: absolute;
  top: 40%;
  left: 50%;
}

.paymentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    margin-top: 2px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    padding: 0;
  }
}

.orderError,
.notFoundError {
  margin: 11px 0 12px 0;

  @media (--viewportMedium) {
    margin: 10px 0 12px 0;
  }
}
.orderError {
  color: var(--failColor);
}
.notFoundError {
  color: var(--attentionColor);
}

.speculateError {
  color: var(--failColor);
  margin: 19px 0 1px 0;

  @media (--viewportLarge) {
    margin: 28px 48px 0 48px;
  }
}

.paymentForm {
  flex-grow: 1;
}

/* Details column is visible only on desktop */
.detailsContainerDesktop {
  display: none;

  @media (--viewportLarge) {
    flex-shrink: 0;
    display: block;
    /* Dimensions */
    width: 450px;
    padding-bottom: 25px;

    /* Position */
    margin: 120px 0 24px 0;

    /* Coloring */
    border: 1px solid var(--matterColorNegative);
    border-radius: 2px;
  }

  @media (--viewportLargeWithPaddings) {
    width: 547px;
  }
}

.detailsAspectWrapper {
  position: relative;
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.detailsHeadings {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0 48px;
  }
}

.detailsTitle {
  margin-bottom: 10px;
  font-family: var(--fontTitle);
  font-weight: 500;
  font-size: 24px;

  @media (--viewportLarge) {
    margin-top: 17px;
    margin-bottom: 9px;
  }
}

.detailsSubtitle {
  @apply --marketplaceH5FontStyles;

  /* Reset margins from font styles */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookingBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  /* Layout spacing */
  margin: 5px 24px 25px 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 26px 48px;
  }
}

.bookingBreakdown {
  @media (--viewportLarge) {
    margin: 0 48px;
  }
}

.note {
  padding: 0 24px;
  font-size: 16px;
  
  @media (--viewportLarge) {
    padding: 0;
  }
}

.warningModalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;

  @media (--viewportMedium) {
    flex-basis: 576px;
    min-height: auto;
    height: auto;
  }
}

.modalTitleContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.modalTitle {
  font-size: 18px;
}

.modalBtn {
  @apply --marketplaceButtonStyles;
  margin-top: 50px;
  border: 1px solid var(--marketplaceSecondaryColor);

  @media (--viewportLarge) {
    display: inline-block;
  }

  &:hover {
    background: var(--marketplaceSecondaryColor);
    color: var(--matterColorLight);
  }
}