@import '../../styles/propertySets.css';

.root {
}

.footer {
  display: none;
  @media (--viewportMedium) {
    display: block;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.imageContent {
  display: block;
  @media (--viewportSLarge) {
    min-width: 900px;
  }
}

.sectionImages {
  padding: 0;
}

.sectionListImages {
  display: none;
  @media (--viewportMedium) {
    padding: 1rem 0;
    margin: 20px 0 0 0;
    border-top: 1px solid var(--matterColorNegative);
    display: block;
  }
}

.imageWrapper {
  padding: 0 0 1rem 0;
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  z-index: 1;
  margin-bottom: -1rem;
}

.listImageContent {
  display: flex;
  flex-basis: 6rem;
  flex-grow: 0;
  flex-shrink: 0;
  transition: opacity 0.1s;
  cursor: pointer;
  align-items: flex-start;
}

.listImageContent .rootForThumbnailImage {
  opacity: 0.99;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  height: 70px;
  margin-left: 15px;
  @media (--viewportLarge) {
    height: 120px;
  }
}

.listImageContent .rootForThumbnailImage:first-child {
  margin-left: 0;
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    padding: 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative;
  /* allow positioning own listing action bar */
  padding-bottom: 0;

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    /* padding-bottom: 100%; */
    min-height: 700px;
  }

  @media (--viewportLarge) {
    /* padding-bottom: 100%; */
    min-height: 700px;
  }
}

.aspectWrapperVideo {
  composes: aspectWrapper;
  &::before {
    background: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 62 62' width='62px' height='62px' preserveAspectRatio='defer' shape-rendering='geometricPrecision'%3E%3Cg fill='white'%3E%3Cg fill-rule='evenodd'%3E%3Cpath d='M31 61.5C14.155 61.5.5 47.845.5 31S14.155.5 31 .5 61.5 14.155 61.5 31 47.845 61.5 31 61.5zm0-3c15.188 0 27.5-12.312 27.5-27.5S46.188 3.5 31 3.5 3.5 15.812 3.5 31 15.812 58.5 31 58.5z' fill-rule='nonzero'/%3E%3Cpath d='M23.35 14.25l24.696 14.92a2 2 0 0 1 0 3.423L23.35 47.513a2 2 0 0 1-3.034-1.713V15.962a2 2 0 0 1 3.034-1.712z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
      50% no-repeat;
    background-size: cover;
    width: 3rem;
    height: 3rem;
    transform: translate(-50%, -50%);
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 2;
    opacity: 0.8;
    transition: opacity 0.2s;
    @media (--viewportMedium) {
      width: 6rem;
      height: 6rem;
    }
  }
}

.actionBar {
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1;
  /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.ownListingTextPendingApproval {
  color: var(--attentionColor);
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editIcon {
  margin: -2px 2px 0 0;
  & g {
    fill: #000000;
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: relative;
  top: 0;
  left: 0;
  width: 90%;
  height: auto;
  max-height: 600px;
  display: block;
  margin: 0 auto;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    max-height: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    object-fit: contain;
    transform: translate(-50%, -50%);
  }

  @media (--viewportLarge) {
    max-height: 600px;
  }

  @media (--viewportSLarge) {
    max-height: 600px;
  }
}

.rootForThumbnailImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: relative;
    object-fit: cover;
    border: 1px solid var(--matterColorNegative);
    width: auto;
    height: auto;
    margin-right: 0;
    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.thumbnailVideoWrapper {
  position: relative;
  margin-left: 15px;
  &::before {
    @media (--viewportMedium) {
      background: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 62 62' width='62px' height='62px' preserveAspectRatio='defer' shape-rendering='geometricPrecision'%3E%3Cg fill='white'%3E%3Cg fill-rule='evenodd'%3E%3Cpath d='M31 61.5C14.155 61.5.5 47.845.5 31S14.155.5 31 .5 61.5 14.155 61.5 31 47.845 61.5 31 61.5zm0-3c15.188 0 27.5-12.312 27.5-27.5S46.188 3.5 31 3.5 3.5 15.812 3.5 31 15.812 58.5 31 58.5z' fill-rule='nonzero'/%3E%3Cpath d='M23.35 14.25l24.696 14.92a2 2 0 0 1 0 3.423L23.35 47.513a2 2 0 0 1-3.034-1.713V15.962a2 2 0 0 1 3.034-1.712z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
        50% no-repeat;
      background-size: cover;
      position: absolute;
      width: 2rem;
      height: 2rem;
      content: '';
      display: block;
      left: 50%;
      top: 50%;
      z-index: 2;
      opacity: 0.8;
      transition: opacity 0.2s;
      transform: translate(-50%, -50%);
      pointer-events: none;
    }
  }
  &::after {
    @media (--viewportMedium) {
      background: var(--matterColor);
      background-size: cover;
      position: absolute;
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      left: 0;
      top: 0;
      z-index: 1;
      opacity: 0.8;
      transition: opacity 0.2s;
      pointer-events: none;
    }
  }
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.containerFluid {
  max-width: auto;
  padding: 0 15px;
  @media (--viewportMedium) {
    max-width: 100%;
    margin: 0 24px 24px 24px;
  }
}
.mainDescription {
  width: 100%;
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
}

.main {
  width: 100%;
  @media (--viewportMedium) {
    margin: 0 auto;
  }
  @media (--viewportLarge) {
    width: auto;
  }
}

.mainFluid {
  display: block;
  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin: 0 auto 20px auto;
  }
  @media (--viewportSLarge) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 20px auto 30px auto;
  }
}
.hyperlink {
  color: var(--matterColor);
  cursor: pointer;
  font-size: 16px;
}
.videoLink {
  color: var(--matterColor);
  font-size: 16px;
  cursor: pointer;
}
.twoColMain {
  @media (--viewportMedium) {
    width: 100%;
  }
  @media (--viewportSLarge) {
    max-width: 900px;
    margin-right: 25px;
    order: 1;
  }

  @media (--viewportSpecificLarge) {
    max-width: 1100px;
  }
}
.sidebar {
  max-width: 100%;
  @media (--viewportMedium) {
    max-width: 100%;
  }
  @media (--viewportSLarge) {
    order: 2;
    font-size: 1.6rem;
    width: 100%;
    min-width: 355px;
    margin-left: 25px;
    margin-top: 25px;
  }
}
.watchList {
  max-width: 100%;
  @media (--viewportMedium) {
    max-width: 100%;
  }
  @media (--viewportLarge) {
    order: 3;
    max-width: 20%;
  }
}
.mainContent {
  @media (--viewportMedium) {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    margin-right: 2rem;
  }

  @media (--viewportLarge) {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    margin-right: 2rem;
  }
}

.tradingPanel {
  @media (--viewportMedium) {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 0%;
  }

  @media (--viewportLarge) {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    order: 2;
  }
}

.sectionWatchList {
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  border-bottom: 1px solid var(--matterColorNegative);
  @media (--viewportMedium) {
    padding: 30px 0;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none;
    /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none;
  /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.sectionHeading {
  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.title {
  line-height: normal;
  /* Font */
  font-size: 24px;
  font-weight: normal;
  /* Layout */
  width: 100%;
  margin-top: 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH5FontStyles;
  margin-top: 7px;
  margin-bottom: 0;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* .authorNameLink {
  color: var(--matterColor);
} */

.contactWrapper {
  display: block;
  margin-top: 25px;

  @media (--viewportMedium) {
    margin: 25px auto auto auto;

    & button {
      max-width: 177px;
      & span {
        font-size: 14px;
      }
    }
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  margin: 0;
}

.otherSection {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--matterColorNegative);
  padding: 20px 0 25px 0;

  @media (--viewportMedium) {
    padding: 25px 25px 30px;
  }
}

.lastSection {
  padding: 60px 24px;
  text-align: center;
  & button {
    margin: auto;
    max-width: 175px;
  }
  @media (--viewportMedium) {
    padding: 80px 0;
  }
}

.sectionTitle {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColorDark);
  font-weight: normal;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 0;
}

.sectionContent {
  margin-top: 20px;
}

.containerDescription {
  display: flex;
  flex-direction: row;
}

.description {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  font-weight: var(--fontWeightSemiBold);

  max-width: 900px;
  margin: 25px auto 0 auto;
}
.descriptionOdd {
  margin-left: 10px;
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 28px;
  }
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.reviewsHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }

  @media (--viewportLarge) {
    margin: 50px 0 26px 0;
  }
}

.sectionHost {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.yourHostHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }

  @media (--viewportLarge) {
    margin: 53px 0 27px 0;
  }
}
.videoModalScrollLayer {
  @apply --marketplaceModalRootStyles;
  background-color: var(--matterColorLight);
  min-height: 100vh;
  overflow: auto;

  @media (--viewportMedium) {
    padding: 0;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: none;
  }
}
.videoModalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;

  @media (--viewportMedium) {
    flex-basis: 980px;
    min-height: auto;
    height: auto;
  }
}
.videoModalContent {
  height: 100vh;
  position: relative;
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
  font-family: 'DM Serif Text', serif;
  line-height: 1.5;
  letter-spacing: -0.3;
}

.sectionDetails {
  padding: 0;
  display: grid;
  margin-top: 25px;
  grid-template-columns: 150px auto;
  column-gap: 20px;
  word-break: break-word;
}

.detail {
  margin-top: 0;
  margin-bottom: 1rem;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  display: flex;
  flex-direction: row;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1rem;
  }
}
.detail span {
  padding-right: 5px;
}

.categoryTitle {
  text-transform: uppercase;
}

.readMoreTarget {
  display: block;
  @media (--viewportMedium) {
    display: flex;
  }
}
.readMoreLink {
  color: #057bc4;
  letter-spacing: 1px;
  cursor: pointer;
  font-size: 14px;
}

.ctaButton {
  @media (--viewportMedium) {
    margin-left: 0;
  }
  @media (--viewportLarge) {
    display: flex;
    order: 3;
    flex-direction: column;
    margin-left: 20px;
  }
}

.ctaButtonMain {
  flex: 1;
}

.ctaButtonMain:last-child {
  margin-top: 10px;
}

.sectionMakeOffer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: 100%;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 20px;
  }
}

.btn {
  border: 2px solid var(--matterColorNegative);
  background: #fff;
  color: rgba(16, 16, 16);
  fill: rgba(16, 16, 16);
  width: 100%;
  white-space: normal;
  min-height: 43px;
  padding: 0 20px;
  max-width: 130px;
}
.btn > * {
  font-size: 13px;
}

.btn:hover,
.btn:focus {
  background-color: #fff;
  box-shadow: none;
  color: rgba(16, 16, 16, 0.8);
  fill: rgba(16, 16, 16, 0.8);
}

/* WATCHLIST */
.watchButton {
  border-color: var(--matterColorNegative);
  background: var(--matterColorLight);
  color: var(--matterColorDark);
  width: 142px;
  white-space: normal;
  max-height: 43px;
}

.watchedButton {
  composes: watchButton;
  border: none;
  background: var(--marketplaceSecondaryColor);
  color: var(--matterColorLight);
}

.watchButton > *,
.watchedButton > * {
  font-size: 13px;
}

.watchButton:hover,
.watchButton:focus,
.watchedButton:hover,
.watchedButton:focus {
  box-shadow: var(--boxShadowButton);
}

.numWatchersLabel {
  font-size: 16px;
  line-height: 50px;
  color: var(--marketplaceSecondaryColor);
}

/* OLD */
.sectionPrice {
  width: 66%;
  text-align: right;
}

.priceContainer {
  /* Layout */
  display: none;
  font-weight: 600;
  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
    font-weight: 600;
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
    font-weight: 600;
  }
}

.container {
  flex: 1;
}

.askingPrice {
  min-width: 100px;
  margin-right: 30px;
  font-size: 20px;
}
.priceValue {
  font-size: 20px;
}

.modalTitle {
  text-align: center;
}
.readmoreContent {
  display: flex;
}
.row {
  display: flex;
}
.col {
  flex: 0 0 50%;
}
.category {
  text-align: left;
  text-transform: uppercase;
}
.column {
  flex: 0 0 100%;
}

/* ITEM DETAILS */
.techTitle {
  text-transform: uppercase;
  font-weight: bold;
  font-family: var(--fontTitle);
  font-size: 12px;
  @media (--viewportXSmall) {
    font-size: 16px;
  }
}

.techSubtitle {
  text-transform: capitalize;
  font-family: var(--fontTitle);
  font-weight: bold;
  font-size: 12px;
  @media (--viewportXSmall) {
    font-size: 16px;
  }
}

.techValue {
  text-transform: capitalize;
  font-weight: normal;
  font-size: 12px;
  @media (--viewportXSmall) {
    font-size: 16px;
  }
}

.messageSellerLink {
  text-decoration: underline;
  color: var(--marketplaceSecondaryColor);
  cursor: pointer;
}

.editListingLink {
  composes: messageSellerLink;
}

/* FEATURED RECORDS */
.recordsCarousel {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-width: none;
  margin-top: 25px;
  @media (--viewportLarge) {
    margin: 25px auto auto auto;
  }
}

.recordsCarousel::-webkit-scrollbar {
  display: none;
}

.featuredRecords {
  width: 100%;
  @media (--viewportLargeWithPaddings) {
    width: 100%;
  }
}

.featuredRecord {
  display: inline-block;
  margin-bottom: 36px;
  width: 150px;
  text-align: center;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  & img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
}

/* DOCUMENTS - RELATED STUFF */
.url {
  display: block;
  margin-top: 10px;
  font-size: 16px;
  text-decoration: underline;
  text-align: center;
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (--viewportMedium) {
    width: 100%;
  }
}

.listItem {
  display: inline-block;
  flex-basis: 150px;
  margin-right: 10px;
  margin-top: 30px;
  @media (--viewportMedium) {
    margin-right: 50px;
    flex-basis: 150px;
  }
}

.docName {
  display: block;
  line-break: auto;
}

/* SECTION WRAPPER */
.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdownBtn {
  cursor: pointer;
  outline: 0;
  border: none;
}

/* CAROUSEL NAVIGATION BUTTONS */
.prevBtn,
.nextBtn {
  /* Position and dimensions */
  position: absolute;
  top: 0;
  bottom: 0;
  width: 10px;
  /* Clear button borders */
  border: none;
  border-radius: 0;

  outline: none;
  cursor: pointer;

  background-size: 13px auto;

  @media (--viewportMedium) {
    background-size: 20px auto;
    width: 15px;
  }

  @media (--viewportLarge) {
    opacity: 0.5;
    transition: var(--transitionStyleButton);

    &:hover {
      opacity: 1;
    }
  }
}

.prevBtn {
  left: 0;
  background-image: url('data:image/svg+xml;utf8,<svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg"><path d="M5.667 1c.094 0 .188.04.254.118.12.14.102.35-.038.47L1.85 5l4.032 3.412c.14.12.158.33.04.47-.12.14-.33.158-.47.04L1.117 5.254C1.043 5.19 1 5.098 1 5s.043-.19.118-.255L5.45 1.08c.064-.054.14-.08.217-.08" stroke="%2E6E6" fill="%2E6E6" fill-rule="evenodd"/></svg>');
  background-position: center left;

  @media (--viewportMedium) {
    background-position: center left;
    background-image: url('data:image/svg+xml;utf8,<svg width="20" height="31" viewBox="0 0 20 31" xmlns="http://www.w3.org/2000/svg"><path d="M18.6667957 0c.37866928 0 .75333854.1550012 1.0186737.4585452.47466996.5425042.4040028 1.3575521-.1573344 1.8199723L3.3986902 15.5001192 19.528135 28.72172085c.5613372.46242022.63200436 1.27746815.1573344 1.821264-.47733663.54508752-1.31734243.61096303-1.880013.15241784L.4720033 16.4869601C.1720012 16.2402499 0 15.8798721 0 15.5001192c0-.3797529.1720012-.7401307.4720033-.9868409L17.8054564.3048357C18.0561248.1007508 18.3627936 0 18.6667957 0" fill="%2E6E6" fill-rule="evenodd"/></svg>');
  }
}

.nextBtn {
  right: 0;
  background-image: url('data:image/svg+xml;utf8,<svg width="7" height="10" viewBox="0 0 7 10" xmlns="http://www.w3.org/2000/svg"><path d="M1.333 9c-.094 0-.188-.04-.254-.118-.12-.14-.102-.35.038-.47L5.15 5 1.118 1.588c-.14-.12-.158-.33-.04-.47.12-.14.33-.158.47-.04l4.334 3.667C5.957 4.81 6 4.902 6 5s-.043.19-.118.255L1.55 8.92c-.064.054-.14.08-.217.08" stroke="%2E6E6" fill="%2E6E6" fill-rule="evenodd"/></svg>');
  background-position: center right;

  @media (--viewportMedium) {
    background-position: center right;
    background-image: url('data:image/svg+xml;utf8,<svg width="20" height="31" viewBox="0 0 20 31" xmlns="http://www.w3.org/2000/svg"><path d="M1.3332 31c-.37866 0-.75333-.1550012-1.01867-.4585452-.47467-.5425042-.404-1.3575521.15734-1.8199723l16.12944-13.2216017L.47187 2.27827915C-.08947 1.81585893-.16014 1.000811.31453.45701515.79187-.08807237 1.63187-.15394788 2.19454.3045973L19.528 14.5130399c.3.2467102.472.607088.472.9868409 0 .3797529-.172.7401307-.472.9868409L2.19454 30.6951643C1.94388 30.8992492 1.63721 31 1.3332 31" fill="%2E6E6" fill-rule="evenodd"/></svg>');
  }
}

/* SECTION SHIPPING OPTIONS */
.sectionShippingOptions {
  padding: 10px 0 20px 0;
  display: flex;
  justify-content: space-between;
}

.shippingOptionsColumn {
  flex-basis: 142px;
  display: flex;
  flex-direction: column;
  & span {
    font-size: 14px;
  }
}

.shippingLabel {
  padding-left: 30px;
  line-height: 20px;
  position: relative;
}

.shippingLabel::before {
  content: '';
  display: inline-block;
  position: absolute;
  left: 2px;
  width: 20px;
  height: 20px;
  background: url('../../assets/shippingIcon.png') no-repeat;
}

.soldText {
  color: var(--marketplaceSecondaryColor);

  &::before {
    content: ' - ';
    display: inline;
    color: var(--matterColor);
  }
}

.phoneContact {
  font-size: 14px;
  font-family: var(--fontTitle);
  text-transform: uppercase;
  display: flex;
  gap: 5px;
  @media (--viewportMedium) {
    display: block;
  }
}

.phoneContactIcon {
  width: 20px;
  height: auto;
  margin: 0 7px 0 3px;
}

.searchListingsPanel {
  flex-grow: 1;
}

.loader {
  width: 100px;
  margin: 20px auto;
}

.sectionTimedAuction {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--matterColorNegative);
}

.countdownTimer {
  display: flex;
  justify-content: space-between;
  color: var(--marketplaceSecondaryColor);
  font-weight: bold;
  text-transform: uppercase;
  & p {
    font-size: 14px;
    @media (--viewportMedium) {
      font-size: 20px;
    }
  }
}

.reservePriceText {
  color: var(--marketplaceSecondaryColor);
  margin-top: 10px;
  font-size: 20px;
  @media (--viewportMedium) {
    font-size: 24px;
  }
}

.informText {
  font-family: var(--fontTitle);
  color: var(--marketplaceSecondaryColor);
  margin-top: 10px;
  font-size: 20px;
  @media (--viewportMedium) {
    font-size: 24px;
  }
}

.endTimedAuctionLabel {
  @compose: reservePrice;
  color: var(--marketplaceSecondaryColor);
}

.extendedTime {
  text-transform: uppercase;
  color: var(--marketplaceSecondaryColor);
  margin: 0;
}

.sectionShare {
  display: flex;
  justify-content: flex-end;

  & a {
    margin-left: 20px;
  }
}

.estPriceLabel {
  color: var(--matterColorAnti);
  font-size: 16px;
  font-family: var(--fontTitle);
}

.estPriceValue {
  color: var(--matterColorAnti);
  font-size: 16px;
}

.modalContainer {
  @apply --marketplaceModalBaseStyles;
  padding-top: 70px;
}

/* SECTION COLLECTION FILTERS */
.filters {
  display: flex;
  justify-content: start;
  align-content: center;
  margin-bottom: 20px;
}

.filterButton {
  max-width: 200px;
  border: 1px solid var(--failColor);
  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &:hover {
    border: 1px solid var(--failColor);
    /* background-color: var(--failColor); */
    /* color: var(--matterColorLight); */
  }
}

.activeFilterButton {
  border: 1px solid var(--failColor);
  background-color: var(--failColor);
  color: var(--matterColorLight);
}
