@import "../../styles/propertySets.css";

.root {
  /* Fixed on top of everything */
  position: fixed;
  bottom: 0;
  z-index: 10000;

  /* Dimensions */
  width: 100vw;
  padding: 5px 0;

  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Colors */
  background-color: var(--matterColor);
  color: var(--matterColorAnti);

  /* Cover Topbar on desktop */
  @media (--viewportMedium) {
    padding: 8px 0;
  }
}

.message {
  @apply --marketplaceH5FontStyles;
  margin-left: 24px;
}

.cookieLink {
  color: var(--matterColorLight);
  border-bottom: 1px solid var(--matterColorNegative);

  &:hover {
    text-decoration: none;
  }
}

.continueBtn {
  /* Font */
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);

  /* Dimensions */
  padding: 3px 12px 3px 12px;
  margin: 0 24px 0 6px;

  background-color: transparent;
  border: solid 1px var(--matterColorAnti);
  border-radius: 4px;
  cursor: pointer;

  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--matterColorNegative);
    color: var(--matterColor);
    transition: var(--transitionStyleButton);
  }

  @media (--viewportMedium) {
    margin: 0 24px;
  }
}
