@import "../../styles/propertySets.css";

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }

  @media (--viewportLarge) {
    min-width: 900px;
  }
}

.loadingScreen {
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--marketplaceColorLight);
  z-index: 2;
}

.loader {
  position: absolute;
  top: 40%;
  left: 50%;
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 24px;
  }
}

.optionalLabel {
  display: none;
  @media (--viewportMedium) {
    color: var(--matterColorAnti);
    display: inline-block;
    margin-left: 5px;

    &::before {
      content: "-";
      display: inline-block;
      margin-right: 5px;
    }
  }
}

.title {
  composes: formMargins;
}

.description {
  composes: formMargins;
  flex-shrink: 0;
}

.linkImages {
  composes: formMargins;
}

.submitButton {
  margin: 42px auto 24px auto;
  flex-shrink: 0;
  border: 1px solid var(--marketplaceSecondaryColor);
  border-radius: 5px;
  text-align: center;
  padding: 10px 25px;
  width: 241px;

  @media (--viewportLarge) {
    display: inline-block;
  }

  &:hover {
    background: var(--marketplaceSecondaryColor);
    color: var(--matterColorLight);
  }
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.paragraph {
  border: 1px solid #2ecc71;
  background: none;
  color: #2ecc71;
}
.paragraph:hover,
.paragraph:focus {
  background: none;
  box-shadow: none;
}

.removeParagraph {
  margin-bottom: 20px;
}
.priceInput {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 24px;
}

.dateServiceInput {
  z-index: 1;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.features {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.hyperlink {
  composes: formMargins;
  flex-shrink: 0;
}

.shipping {
  composes: formMargins;
  flex-shrink: 0;
}
.details {
  composes: formMargins;
  flex-shrink: 0;
}
.micpair {
  composes: formMargins;
  flex-shrink: 0;
}
.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.content {
  flex: 1;
}
.content:last-child {
  margin-left: 30px;
}
.titleContent {
  text-align: center;
  flex: 0 0 100%;
}

.imagesField {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.youtubeField {
  position: relative;
}

/* FEATURED RECORDS */
.sectionFeaturedRecord {
  position: relative;
}

.recordField {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  @media (--viewportMedium) {
    align-items: center;
  }
}

.recordImageField {
  flex-basis: 100px;
  @media (--viewportMedium) {
    flex-basis: 200px;
  }
}

.featuredImageField {
  position: relative;
  width: 100px;
  margin: 12px 0 24px 0;

  @media (--viewportMedium) {
    width: 100%;
    max-width: 200px;
    min-height: 200px;
  }

  & > img {
    width: 100%;
    height: 100%;
    min-height: 85px;

    @media (--viewportMedium) {
      min-height: 200px;
    }
  }
}

.addSquareImageWrapper {
  composes: addImageWrapper;
  margin: 12px 0 24px 0;
  width: 100px;
  @media (--viewportMedium) {
    width: 100%;
  }
}

.aspectRatioSquareWrapper {
  width: 100%;
  padding-top: 100%;
  @media (--viewportMedium) {
    width: 200px;
    padding-top: 200px;
  }
}

.featuredHyperlink {
  margin: 0 0 24px 24px;
  width: 55%;
}

.featuredThumbnail {
  composes: thumbnail;
  width: 100%;
  margin: 12px 0 0 0;
  @media (--viewportLarge) {
    width: 200px;
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}

.featuredImageTypes {
  composes: imageTypes;
  text-align: center;
  display: none;
  @media (--viewportMedium) {
    display: inline;
  }
}

/* FILE UPLOAD */

.addFile {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorGray);
    width: 300px;
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.addFileInput {
  display: none;
}

.addFileWrapper {
  position: relative;
  width: 100%;
  margin: 14px 0 0 0;
  overflow: hidden;

  &::after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: calc(50% - 12px);
  }
}

.addFileFullWidthWrapper {
  position: relative;
  width: 100%;
  margin: 14px 0 0 0;
  overflow: hidden;

  &::after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

.fillWidth {
  width: 100%;
  @media (--viewportLarge) {
    width: 50%;
  }
}

.aspectRatioFileWrapper {
  padding-bottom: 42px;
}

.authDocField {
  display: block;
  margin-bottom: 42px;
  @media (--viewportLarge) {
    display: flex;
    justify-content: space-between;
  }
}

.uploadedFileName {
  display: flex;
  justify-content: space-between;
}

/* REMOVE BUTTONS */
.removeButton {
  position: absolute;
  color: var(--marketplaceSecondaryColor);
  outline: 0;
  border: none;
  cursor: pointer;
  & svg {
    width: 15px;
    height: 15px;
  }
  & span {
    display: inline-block;
    margin-left: 5px;
  }
}

.removeTextFieldButton {
  top: 24px;
  right: 0px;
  @media (--viewportLarge) {
    right: -100px;
  }
}

.removeFileUploadRowButton {
  right: -5px;
  top: -5px;
}

.removeFeaturedRecordButton {
  position: absolute;
  top: 0;
  right: 0;
  @media (--viewportMedium) {
    position: relative;
    top: 0;
  }
}

.removeRecordImageButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 33px;
  height: 33px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 0;
  border-bottom-left-radius: 2px;
  cursor: pointer;

  & svg {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 10px;
    height: 10px;
    fill: var(--matterColorAnti);
    stroke: var(--matterColorAnti);
  }

  &:hover svg {
    fill: var(--matterColorLight);
    stroke: var(--matterColorLight);
  }
}

.estPriceInput {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.estPriceInputField {
  width: 200px;
  margin-bottom: 12px;
}
