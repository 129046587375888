.root {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1428px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1256px;
    padding: 0;
  }
  }
  
  .form {
    flex-grow: 1;
  }
  
  .title {
    text-align: center;
    margin-bottom: 19px;
    @media (--viewportLarge) {
      margin-bottom: 38px;
      padding: 1px 0 7px 0;
    }
  }
  