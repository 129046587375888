.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }

  @media (--viewportLarge) {
    min-width: 900px;
  }
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.hyperlink {
  composes: formMargins;
  flex-shrink: 0;
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  border: 1px solid var(--marketplaceSecondaryColor);

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }

  &:hover {
    background: var(--marketplaceSecondaryColor);
    color: var(--matterColorLight);
  }
}

.optionalLabel {
  color: var(--matterColorAnti);
  display: inline-block;
  margin-left: 5px;

  &::before {
    content: "-";
    display: inline-block;
    margin-right: 5px;
  }
}

.youtubeField {
  position: relative;
  width: 100%;
  @media (--viewportLarge) {
    width: 85%;
  }
}

.removeTextFieldButton {
  top: 24px;
  right: 0;
  @media (--viewportLarge) {
    right: -100px;
  }
}
