@import "../../styles/propertySets.css";

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
    max-width: 564px;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.title {
  margin-top: 8px;
  margin-bottom: 19px;

  @media (--viewportMedium) {
    margin-bottom: 36px;
  }
}

.addressResult {
  width: 100%;
  max-width: 300px;
  height: 50px;
  padding: 5px 10px;
  display: table-cell;
  vertical-align: middle;
  border: 1px solid var(--matterColor);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.removeActionBtn {
  @apply --marketplaceSmallFontStyles;

  border: none;
  color: var(--matterColorAnti);
  cursor: pointer;
}
