.root {
}

.loading {
  margin-left: 24px;
}

.searchbar {
  margin: auto 24px 48px 24px;
  display: flex;
  gap: 10px;
  & label {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    @media (--viewportLarge) {
      margin-top: 0;
      font-size: 24px;
    }
  }
  & input {
    max-width: 100%;
    @media (--viewportMedium) {
      max-width: 50vw;
    }
  }
}

.tabs {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-end;
    height: 55px;
    align-items: flex-end;
    padding: 13px 24px 0 24px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.listingPanel {
  width: 100%;
  margin: 24px auto 0 auto;

  @media (--viewportMedium) {
    margin: 48px auto 0 auto;
  }

  @media (--viewportLarge) {
    margin: 80px auto 0 auto;
    max-width: 100vw;
  }
}

.title {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 24px 48px 24px;
  }

  @media (--viewportLarge) {
    margin: 0 36px 48px 36px;
  }
}

.listingCards {
  padding: 0 24px 96px 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (--viewportLarge) {
    padding: 0 0 96px 0;
  }
}

.listingCard {
  margin-bottom: 36px;
  flex-basis: 100%;

  @media (--viewportMedium) {
    flex-basis: calc(50% - 12px);
    margin-right: 6px;
    margin-left: 6px;
  }

  @media (--viewportLargeWithPaddings) {
     flex-basis: calc(25% - 24px);
     margin-right: 12px;
     margin-left: 12px;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin: auto 24px 0 24px;

  @media (--viewportLarge) {
    margin: auto 36px 0 36px;
  }
}
