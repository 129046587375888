.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }

  @media (--viewportLarge) {
    min-width: 900px;
  }
}

.features {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  border: 1px solid var(--marketplaceSecondaryColor);

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }

  &:hover {
    background: var(--marketplaceSecondaryColor);
    color: var(--matterColorLight);
  }
}

/* FILE UPLOAD */

.addFile {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorGray);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.addFileInput {
  display: none;
}

.addFileWrapper {
  position: relative;
  width: 100%;
  margin: 14px 0 0 0;
  overflow: hidden;

  &::after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: calc(50% - 12px);
  }
}

.addFileFullWidthWrapper {
  position: relative;
  width: 100%;
  margin: 14px 0 0 0;
  overflow: hidden;

  &::after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

.fillWidth {
  width: 100%;
  @media (--viewportLarge) {
    width: 50%;
  }
}

.aspectRatioFileWrapper {
  padding-bottom: 42px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.authDocField {
  position: relative;
  display: block;
  margin-bottom: 42px;
  @media (--viewportLarge) {
    display: flex;
    justify-content: space-between;
  }
}

.uploadedFileName {
  display: flex;
  justify-content: space-between;
}

.removeFileUploadRowButton {
  right: -5px;
  top: -5px;
}

.optionalLabel {
  color: var(--matterColorAnti);
  display: inline-block;
  margin-left: 5px;

  &::before {
    content: "-";
    display: inline-block;
    margin-right: 5px;
  }
}

.loader {
  text-align: center;
}
