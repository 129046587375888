@import '../../styles/propertySets.css';

.root {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
}

.steps {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  flex: 1;
  margin-bottom: 28px;

  & p {
    font-size: 14px;
    line-height: 20px;
  }

  @media (--viewportMedium) {
    margin-right: 52px;
    margin-bottom: 0;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 0;
  margin-bottom: 18px;
  font-weight: var(--fontWeightMedium);

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.pronounication {
  color: #3269a8;
}
