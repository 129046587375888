.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  padding: 0;

  @media (--viewportMedium) {
    min-height: 400px;
    max-height: none;
  }

  @media (--viewportLarge) {
    height: calc(50vh - var(--topbarHeightDesktop));
  }
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 43px;
  }

  @media (--viewportLarge) {
    justify-content: center;
  }
}

.sections {
  margin: 0;
}

.section {
  max-width: 1300px;
  margin: auto;
  padding: 24px;
}

.sectionLargeCards {
  composes: section;
  max-width: none;
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1428px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1256px;
    padding: 0;
  }
}

.informationContentWrapper {
  display: block;
  width: 300px;
  height: 200px;
  border: none;
  @media (--viewportMedium) {
    width: 600px;
    height: 300px;
  }

  @media (--viewportLargeWithPaddings) {
    width: 900px;
    height: 500px;
  }
}

.informationImage {
  display: block;
  width: 300px;
  height: auto;
  border: none;
  @media (--viewportMedium) {
    width: 600px;
  }

  @media (--viewportLargeWithPaddings) {
    width: 900px;
  }
}
