@import '../../../styles/propertySets.css';

.layoutWrapperMain {
  min-height: calc(100vh - var(--topbarHeight));
  padding: 0;

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.root {
  @apply --marketplaceModalRootStyles;

  @media (--viewportMedium) {
    background-color: var(--matterColor);
    background-size: cover;
    background-position: center center;
  }
}

.logo {
  width: 58%;
  align-self: center;
  max-width: 252px;
  margin-bottom: 6px;
}

.content {
  @apply --marketplaceModalBaseStyles;
  padding: 10px;
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: none;
}

.tabs {
  @media (--viewportMedium) {
    margin-top: 6px;
  }
}

.tab {
  @apply --marketplaceModalTitleStyles;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.form {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 10px;
    padding-top: 2px;
  }
}

.loginForm {
  margin-top: 10px;
  flex-grow: 0;
  flex-basis: 260px;

  @media (--viewportMedium) {
    flex-basis: 330px;
    margin-top: 0;
    padding-top: 2px;
  }
}

.signupForm {
  margin-top: 10px;
  flex-grow: 0;
  flex-basis: 340px;

  @media (--viewportMedium) {
    flex-basis: 410px;
    margin-top: 0;
    padding-top: 2px;
  }
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
  text-align: left;
}


.termsWrapper {
  width: 100%;
  padding-top: 40px;

  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.termsHeading {
  @apply --marketplaceH1FontStyles;
  margin: 0 0 19px 0;

  @media (--viewportMedium) {
    margin: 0 0 19px 0;
  }
}

.closeText {
  @apply --marketplaceModalCloseText;
}

.closeIcon {
  @apply --marketplaceModalCloseIcon;
}

/* ================ Errors ================ */

.error {
  @apply --marketplaceModalErrorStyles;
}

.verifyClose {
  @apply --marketplaceModalCloseStyles;

  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;
}

.closeText {
  @apply --marketplaceModalCloseText;
}

.closeIcon {
  @apply --marketplaceModalCloseIcon;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

/* Helper links */
.modalHelperLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceModalHelperLink;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  @apply --marketplaceModalHelperText;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

/* ================ Social logins & SSO ================ */

.signupWithIdpTitle {
  @apply --marketplaceModalTitleStyles;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 16px;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    margin-top: 6px;
  }
}

.confirmInfoText {
  @apply --marketplaceH4FontStyles;
}

.buttonIcon {
  position: absolute;
  left: 0;
  margin-left: 20px;
}

.socialButtonWrapper {
  margin-bottom: 6px;

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.socialButtonsOr {
  width: 100%;
  height: 32px;
  margin: 0;
  text-align: center;
  position: relative;
  background-color: var(--matterColorLight);

  &:after {
    content: '';
    width: 100%;
    border-bottom: solid 1px #d2d2d2;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }

  @media (--viewportMedium) {
    height: 34px;
  }
  
}


.socialButtonsOrText {
  background-color: var(--matterColorLight);
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}

.hidden {
  display: none;
}

.authenticationModalContainer {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  border-bottom: none;
  padding: 5px 40px;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    flex-basis: 512px;
    min-height: auto;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.tabRoot {
  & > button {
    padding: 5px 10px;
    
    & > h1 {
      font-size: 30px;
      line-height: 1.2;
    }
  }
}