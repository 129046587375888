@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH2FontStyles;
  font-weight: var(--fontWeightMedium);

  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportSLarge) {
    font-size: 24px;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 345px;
}

.listingCards {
  padding: 30px 0;

  @media (--viewportMedium) {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}
.listingCardsInner {
  @media (--viewportMedium) {
    padding: 1rem 0;
    overflow-x: auto;
    overflow-y: hidden;
    flex-basis: 100%;
    width: auto;
    position: relative;
    z-index: 1;
  }
}
.rowList {
  flex-wrap: nowrap;
  margin-bottom: 0;
  justify-content: flex-start;
  display: flex;
}
.colList {
  display: flex;
  flex-direction: column;
  @media (--viewportMedium) {
    width: calc(50% - 12px);
    margin-right: 24px;

    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }

  @media (--viewportLarge) {
    width: calc(25% - 18px);
    margin-right: 24px;

    &:nth-of-type(2n) {
      margin-right: 24px;
    }

    &:nth-of-type(4) {
      margin-right: 0;
    }
  }
}

.listingCard {
  flex-basis: 100%;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}
