@import '../../styles/propertySets.css';

.pageTitle {
  text-align: center;
  font-weight: normal;
  font-size: 35px;
  color: var(--matterColor);
  margin: 0;
}

.staticPageWrapper {
  width: calc(100% - 48px);
  max-width: 1056px;
  margin: 24px auto;
  background: transparent;
  @media (--viewportMedium) {
    width: calc(100% - 72px);
    margin: 30px auto;
    padding: 0;
  }
}

.coverImage {
  margin: 20px 0;
  @media (--viewportMedium) {
    width: 100%;
    border-radius: 4px;
    object-fit: contain;
    margin: 30px 0 25px;
  }
}

.headerWrapper {
  margin: 0 8px;
  
  @media (--viewportMedium) {
    max-width: 808px;
    margin: auto;
  }

  & > h2 {
    font-size: 16px;
    margin: 0 auto 25px;
    color: var(--matterColor);
    line-height: 32px;
  }
}

.contentWrapper {
  display: block;
  margin: 0 8px;

  @media (--viewportMedium) {
    display: flex;
    flex-wrap: nowrap;
    max-width: 808px;
    margin: auto;
  }
}

.contentSide {
  flex-basis: 30%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  
  @media (--viewportMedium) {
    margin-top: 12px;
    margin-left: 72px;
    flex-direction: column;
    justify-content: flex-start;
  }

  & > div h5 {
    font-family: var(--fontTitle);
    font-weight: 500;
    color: var(--matterColorAnti);
    font-size: 12px;

    @media (--viewportMedium) {
      font-size: 14px;
    }
  } 

  & > div span {
    color: var(--matterColor);
    font-size: 24px;
    @media (--viewportMedium) {
      font-size: 30px;
    }
  }
}

.contentMain {
  flex-basis: 70%;

  @media (--viewportMedium) {
    margin: auto;
  }
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  margin-top: 32px;
  margin-bottom: 16px;
}
