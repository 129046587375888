@import '../../styles/propertySets.css';

.root {
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  opacity: 0;
}

.listingPanel {
  width: 100%;
  margin: 24px auto 0 auto;

  @media (--viewportMedium) {
    margin: 48px auto 0 auto;
  }

  @media (--viewportLarge) {
    margin: 80px auto 0 auto;
    max-width: 80vw;
  }
}

.title {
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 24px 48px 24px;
  }

  @media (--viewportLarge) {
    margin: 0 36px 48px 36px;
  }
}

.listingTable {
  width: 100%;
  padding: 0 24px 96px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin: auto 24px 0 24px;

  @media (--viewportLarge) {
    margin: auto 36px 0 36px;
  }
}
