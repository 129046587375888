@import "../../styles/propertySets.css";

.root {
  margin: 20px 0;
}

.cardIcon {
  margin: 0 12px 0 4px;
  width: 24px;
  height: 16px;
  display: inline-block;
}

.closeIcon {
  @apply --marketplaceModalCloseIcon;
  margin-left: 0;
  margin-right: 5px;
}

.iconCheckmark {
  stroke: var(--successColor);
  display: inline-block;
  margin: 12px 0 0 24px;
}

.iconCheckmarkHidden {
  visibility: hidden;
  display: inline-block;
  margin: 0 0 0 24px;
}

.menu {
  max-width: 100%;
  height: auto;
}

.menuLabel {
  text-align: left;
  font-weight: 500;
  padding: 0;
  border-radius: 4px;
  border: 1px solid var(--matterColorNegative);
  transition: all 0.2s ease-out;
  z-index: calc(var(--zIndexPopup) + 1);
  position: relative;

  &:hover {
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorAnti);
  }

  &:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    text-decoration: none;
    border-radius: 4px 4px 0px 0px;
  }
}

.menuLabelWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 12px 24px 10px 16px;
  font-family: var(--fontFamily);
  font-size: 20px;
  color: var(--matterColor);
}

.menuContent {
  position: absolute;
  z-index: var(--zIndexPopup);

  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowFilterButton);
  border-radius: 4px;
  border: 1px solid var(--matterColorNegative);

  transition: all 0.15s ease-out;

  /* When the dropdown is open, we need to hack the box-shadow's position
    to fit the whole dropdown menu component with padding-top

    MenuLabel height 56px + original padding-top 7px results to 63px
  */
  padding: 63px 0 15px 0;
  top: 0px;
}

.menuItem {
  color: var(--matterColor);
  &:hover {
    background-color: var(--matterColorGray);
  }
}

.menuText {
  padding: 12px 8px;
  color: var(--matterColor);
  text-align: left;
  font-weight: 500;
  width: 80%;

  & div {
    font-family: var(--fontFamily);
    font-size: 20px;
  }

  & span {
    font-family: var(--fontFamily);
    font-size: 20px;
  }

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
    background-color: var(--matterColorGray);
  }
}

.iconArrow {
  stroke: var(--matterColor);
  fill: var(--matterColor);
  transition: all 0.2s ease-out;
}

.IconArrowAnimation {
  transform: rotate(180deg);
}

.existingAddress {
  font-family: var(--fontFamily);
}
