@import "../../styles/propertySets.css";

.consignWrapper {
  margin-top: 10px;
  margin-bottom: 30px;
}

.title {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  font-size: 35px;
  line-height: 56px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2rem;
  }
}

.descriptionContainer {
  background-color: var(--backgroundColorDark);

  display: flex;
  width: 100%;
  height: auto;
  padding: 20px 50px;
  justify-content: space-between;

  @media (--viewportLarge) {
    height: 500px;
    padding: 20px 150px;
    flex-direction: row;
  }

  margin-bottom: 30px;
}

.descriptionImage {
  display: none;

  @media (--viewportLarge) {
    display: block;
    @apply --firstConsignImage;
    flex: 1;
  }
}

.descriptionText {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.routeButton {
  @apply --marketplaceButtonStylesPrimary;

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
  width: 250px;
  margin-top: 2rem;
}

.processContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 20px 50px;
  margin-bottom: 30px;

  @media (--viewportLarge) {
    padding: 20px 150px;
  }
}

.processListContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;

  @media (--viewportLarge) {
    flex-direction: column;
  }
}

.processIndexList {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-right: 20px;

  & li {
    width: 68px;
    height: 68px;
    text-align: center;
    line-height: 2em;
    border-radius: 50%;
    border: 1px solid var(--matterColorLighterGray);

    margin-bottom: 30px;
    display: inline-block;
    position: relative;
    font-size: 30px;
    font-weight: 500;

    &::before {
      content: "";
      position: absolute;
      top: -19em;
      left: 1.1em;
      width: 0.1em;
      height: 19em;
      background: var(--matterColorLighterGray);

      @media (--viewportXSmall) {
        top: -11.3em;
        height: 11.3em;
      }

      @media (--viewportMedium) {
        top: -5.2em;
        height: 5.2em;
      }
    }

    &:first-child::before {
      display: none;
    }
  }

  /* desktop version */
  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 724px;
    margin-top: 30px;

    @media (--viewportSLarge) {
      max-width: 980px;
    }

    @media (--viewportMLarge) {
      max-width: 1066px;
    }

    @media (--viewportSpecificLarge) {
      max-width: 1280px;
    }

    @media (--viewportXLarge) {
      max-width: 1620px;
    }

    & li {
      width: 68px;
      height: 68px;
      text-align: center;
      line-height: 2em;
      border-radius: 50%;
      border: 1px solid var(--matterColorLighterGray);

      margin-right: 30px;
      display: inline-block;
      position: relative;
      font-size: 30px;
      font-weight: 500;

      &::before {
        content: "";
        position: absolute;
        top: 1em;
        left: -4.5em;
        width: 4.5em;
        height: 0.1em;
        background: var(--matterColorLighterGray);

        @media (--viewportSLarge) {
          left: -7.6em;
          width: 7.6em;
        }

        @media (--viewportMLarge) {
          left: -8.55em;
          width: 8.55em;
        }

        @media (--viewportSpecificLarge) {
          left: -11em;
          width: 11em;
        }

        @media (--viewportXLarge) {
          left: -14.7em;
          width: 14.7em;
        }
      }

      &:first-child::before {
        display: none;
      }
    }
  }
}

.processText {
  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.processTextItem {
  flex: 1;
  margin-right: 10px;
}

.feeContainer {
  background-color: var(--backgroundColorDark);

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  height: 390px;
  margin: 20px 50px;
  padding: 20px;

  @media (--viewportLarge) {
    margin: 20px 150px;
    padding: 50px;
  }
}

.feeText {
  composes: descriptionText;
  flex: 1;
}

.feeImage {
  display: none;

  @media (--viewportLarge) {
    display: block;
    @apply --secondConsignImage;
    flex: 1;
  }
}

.actionContainer {
  display: flex;
  justify-content: center;
}

.sectionTitle {
  font-size: 24px;
  font-weight: 500;
}

.createSellLink {
  text-decoration: none;
}
