@import "../../styles/propertySets.css";

.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }

  @media (--viewportLarge) {
    min-width: 900px;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
  border: 1px solid var(--marketplaceSecondaryColor);

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }

  &:hover {
    background: var(--marketplaceSecondaryColor);
    color: var(--matterColorLight);
  }
}

.imagesField {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

/* FEATURED RECORDS */
.sectionFeaturedRecord {
  position: relative;
}

.recordField {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  @media (--viewportMedium) {
    align-items: center;
  }
}

.featuredImageField {
  position: relative;
  width: 100%;
  max-width: 85px;
  min-height: 85px;
  margin: 12px 0 24px 0;

  @media (--viewportMedium) {
    max-width: 200px;
    min-height: 200px;
  }

  & > img {
    width: 100%;
    height: 100%;
    min-height: 85px;

    @media (--viewportMedium) {
      min-height: 200px;
    }
  }
}

.addSquareImageWrapper {
  composes: addImageWrapper;
  margin: 12px 0 24px 0;
  @media (--viewportLarge) {
    width: 100%;
  }
}

.aspectRatioSquareWrapper {
  width: 100%;
  padding-top: 100%;
  @media (--viewportMedium) {
    width: 200px;
    padding-top: 200px;
  }
}

.featuredHyperlink {
  margin: 0 0 24px 24px;
  width: 55%;
}

.featuredThumbnail {
  composes: thumbnail;
  width: 100%;
  margin: 12px 0 0 0;
  @media (--viewportLarge) {
    width: 200px;
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}

.featuredImageTypes {
  composes: imageTypes;
  text-align: center;
  display: none;
  @media (--viewportMedium) {
    display: inline;
  }
}

.optionalLabel {
  color: var(--matterColorAnti);
  display: inline-block;
  margin-left: 5px;

  &::before {
    content: "-";
    display: inline-block;
    margin-right: 5px;
  }
}

.loadingScreen {
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--marketplaceColorLight);
  z-index: 2;
}

.loader {
  position: absolute;
  top: 40%;
  left: 50%;
}

.removeFeaturedRecordButton {
  position: absolute;
  top: 0;
  right: 0;
  @media (--viewportMedium) {
    position: relative;
    top: 0;
  }
}

.removeRecordImageButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 33px;
  height: 33px;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  border-radius: 0;
  border-bottom-left-radius: 2px;
  cursor: pointer;

  & svg {
    position: absolute;
    top: 12px;
    left: 12px;
    width: 10px;
    height: 10px;
    fill: var(--matterColorAnti);
    stroke: var(--matterColorAnti);
  }

  &:hover svg {
    fill: var(--matterColorLight);
    stroke: var(--matterColorLight);
  }
}
