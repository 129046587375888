.root {
}

.slateEditor {
  max-width: 800px;
  height: 900px;
  overflow: auto;
}

.slateEditorReadOnly {
  @media (--viewportLargeWithPaddings) {
    max-width: 1305px;
  }

  @media (--viewportSpecificLarge) {
    max-width: 1505px;
  }
}

.uploadImageInput {
  display: none !important;
}

.menu {
  position: relative;
  padding: 1px 11px 8px;
  border-bottom: 2px solid #eee;

  & > * {
    display: inline-block;
  }
  & > * + * {
    margin-left: 15px;
  }
}

.icon {
  font-size: 18px;
  vertical-align: text-bottom;
}

.colorReversedActive {
  color: #fff;
}

.colorReversedNotActive {
  color: #aaa;
}

.colorNotReversedActive {
  color: #000;
}

.colorNotReversedNotActive {
  color: #ccc;
}

.button {
  cursor: pointer;

  &:hover {
    color: #000;
  }
}

.boxShadowOnFocus {
  box-shadow: 0 0 2px blue;
}

.boxShadowOff {
  box-shadow: none;
}

.image {
  display: block;
  max-width: 100%;
  max-height: 400px;
  object-fit: cover;

  &.center {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
}

.blockquote {
  border-left: 2px solid rgb(221, 221, 221);
  padding: 10px 20px 10px 16px;
  color: rgb(170, 170, 170);
  margin: 8px 0px;
}

.code {
  white-space: pre-wrap;
  font-size: 85%;
  font-family: var(--fontRichText);
  background-color: rgba(135, 131, 120, 0.15);
  border-radius: 3px;
  padding: 0.2em 0.4em;
  line-height: normal;
}

.ul {
  margin: 0px;
  padding-inline-start: 24px;
  list-style: initial;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.imageContainer {
  display: flex;

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.right {
    justify-content: flex-end;
  }

  &.wrap-text-align-left {
    float: left;
    margin-right: 10px;
  }

  &.wrap-text-align-right {
    float: right;
    margin-left: 10px;
  }
}

.font_arsenal {
  font-family: 'Arsenal', sans-serif;
}

.font_pt_serif {
  font-family: 'PT Serif', sans-serif;
  font-weight: normal;
}

.font_default

.fontButton {
  border: 1px solid black;
}